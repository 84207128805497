if (window.analytics) {
  window.XO.analytics = XO.analytics || {};
  
  var taxonomyTags = function () {
    if (XO.metadata.page_data.kind == 'results') {
      return $('#photos-context-link').data('photoTag') || $('#photos-context-link').data('albumTag')
    } else {
      return $('#highest-breadcrumb').children().data('albumTag') || $('#highest-breadcrumb').children().data('photoTag')
    }
  }

  var isFilter = function() {
    if (XO.metadata.page_data.kind == 'results') {
      var isFilterLocation = $('#location-filter').data('location-filter')
      return !!taxonomyTags() || isFilterLocation;
    }

    return false;
  }

  var filterType = function() {
    return isFilter() ? "filtered results list" : "unfiltered results list"
  }

  XO.analytics.page_data = {
    pageType: XO.metadata.page_data.kind,
    taxonomyTags: taxonomyTags()
  }

  XO.analytics.isFilter = isFilter();
  XO.analytics.filterType = filterType()

  const { category, name, ...rest } = XO.analytics.page_data;
  analytics.page(category, name, rest);
}

