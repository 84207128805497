import $ from 'jquery';
import gpt from '@tkww/xo.ads.gpt';
import membership from 'xo.membership/dist/xo.membership.deprecated';

window.jQuery = $;
window.$ = $;

window.TKPL = window.TKPL || {};

window.XO = window.XO || {};
window.XO.utilities = window.XO.utilities || {};
window.XO.utilities.isMobile = function () {
  return document.documentElement.clientWidth < 768;
};

window.XO.ads = { gpt };

window.XO.membership = membership;
